//Colores Generales
$color-turquesa: #F8FFFF;
$color-turquesa-hover: #058d92;
$color-turquesa-border: #07adb3;
$color-text: #282828;
$color-text-titles: #20C4CB;
$primary-blue: #e0e1e6;
$color-light1: #f8ffff;
$primary-gray: #F6F8F9;
$color-text-gray: #023246;

// Paleta de colores
$gray-dark: #000; // sidebar, headings, gray dark
$gray-dark-hover: #555454; // sidebar, headings, gray dark
$gray-dark-border: rgb(239, 239, 239); // sidebar, headings, gray dark

$light-white: rgb(246, 248, 249); //content area background, light

$secondary: #555454; // cuerpos de texto, secondary
$primary: white; //opción del menú lateral activa y highlights, primary

$info: #3c5ba3; //Info
$info-hover: #2e457a; //Info
$info-border: #1e3670; //Info

$danger: #c3353f; // Danger
$danger-hover: #912930; // Danger
$danger-border: #bd2c35; // Danger

$card-header: $primary-gray; //$primary;

//$btn-accept-color: #707070;
$btn-accept-color: $color-text-titles;
$btn-accept-hover: $color-turquesa-hover;
$btn-accept-border: $color-turquesa-border;

$light-yellow: #ffd281;
$light-yellow-hover: #ffc760;

$btn-accept-txtcolor: $color-text-titles;
$btn-info-txtcolor: $light-white;
$btn-danger-txtcolor: $light-white;

$text-color: #282828;
$text-color-titles: #515568;
$checkbox-colorBG: $text-color;

$menu-hover-colorBG: rgba(224, 225, 230, 0.555);
$menu-active-txtcolor: $text-color;
$menu-hover-txtcolor: $text-color;

$sidebar-bg-color: $color-turquesa; //#f6f6f6;
$menu-active-color: $color-text-titles; //#d5d5d5;
$submenu-active-color: #E1E1E1; //$text-color;
$layout-table-content-color: #282828;
$badge-color: $menu-active-color;
$badge-text-color: $text-color;

$login-bg-color: #f6f6f6;
$login-box-inputs-color: #f6f6f6;
$login-text-color: #707070;

// Font
@font-face {
  font-family: 'Moderat';
  src: url('./../Assets/Fonts/Moderat/Moderat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('./../Assets/Fonts/Moderat/Moderat-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./../Assets/Fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./../Assets/Fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../Assets/Fonts/QuickSand/Quicksand-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../Assets/Fonts/QuickSand/Quicksand-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

$font: 'Quicksand';
$font-titles: 'OpenSans';
