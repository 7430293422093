.business-partner-level-box{
    width:250px;
    height: 150px;
    background-color: $btn-accept-color;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: background-color  .3s ease-in-out;
    padding: 0;
}

.business-partner-level-box:hover{
    background-color: $btn-accept-hover;
}

.business-partner-selected{
    border: 6px solid $btn-accept-border;
    border-radius: 10px;
    padding: 8px;

}

.business-partner-title-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: bold;
    font-size: 20px;
}
.business-partner-mb-title{
    margin-top: 30px;
    margin-bottom: 20px;
}