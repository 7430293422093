.btn-progress-bar{
    padding: 30px 15px;
    background-color: #fff;
    margin-top: 5px;
    border: 2px solid #a5a5a5;
    color: #727272;
    transition: .3s ease-in-out;
}
button.btn-progress-bar:hover{
    background: #a5a5a5;
    color: white;
}
button.btn-progress-bar:disabled{
    // background-color: #efe3e3;
    pointer-events: none !important;
    cursor: not-allowed ;
}

.bg-progress-bar{
background-color:#00c851;
}

.progress-bar-text{
    color: #727272;
    font-weight: bold;
}

.register-hours{
    margin: 0;
    color: #999898;
}
.not-allowed{
    cursor: not-allowed ;
    pointer-events: none !important;
}


