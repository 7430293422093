/*ALL MODAL TITLES*/
.StandarModalTitle {
  background-color: #F5F5F5;
  line-height: 20px !important;
  height: 57px;
  max-height: 57px;
  size: 13px;
  color: $text-color !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  text-align: left !important;

  inline-size: -webkit-fill-available !important;
  position: absolute !important;
  padding-right: 35px !important;
}
.StandarModalContainer {
  text-align: start;
  padding: 10px 30px;
}
/*CONTAINER*/
.StandarModalHtmlContainer {
  margin: 0px !important;
  padding-left: 10px !important;
  display: flex;
  justify-content: center;
  padding-top: 12% !important;
}
.StandarModalHtmlContainerLogOut {
  margin: 20px !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
.StandarModalPopup {
  width: 900px !important;
  max-width: 900px !important;
  max-height: 500px !important;
  height: auto;
  overflow-y: scroll;
}
.StandarModalPopup::-webkit-scrollbar {
  width: 10px;
  background-color: $secondary;
  border-radius: 2px;
}
.StandarModalPopup::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

/* BUTTONS */
.StandarModalConfirmButton {
  background-color: $btn-accept-color !important;
  color: $btn-accept-txtcolor !important;
  font-size: 18px !important;
  padding: 5px 0px 5px 20px !important;
  margin-top: 0px !important;
}
.StandarModalCancellButton {
  color: $btn-danger-txtcolor !important;
  background-color: $danger !important;
  font-size: 18px !important;
  padding: 5px 20px 5px 20px !important;
  margin-top: 0px !important;
}
/*STYLE LIKE LOGOUT*/
.StandarModalConfirmButtonLogOut {
  background-color: $btn-accept-color !important;
  color: white !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  margin-top: 10px !important;
  border: 0px !important;
  border-radius: 4px !important;
}
.ShortModalConfirmButton{
  background-color: $btn-accept-color !important;
  color: $btn-accept-txtcolor !important;
  font-size: 14px !important;
  padding: 5px 20px !important;
  margin-top: 10px !important;
  border: 0px !important;
}
.StandarModalConfirmButtonLogOut:hover, .ShortModalConfirmButton:hover {
  background-color: $btn-accept-hover !important;
}
.StandarModalCancelButtonLogOut {
  // background-color: $light-white !important;
  // color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px !important;
  width: 220px !important;
  // border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
  border-radius: 4px !important;

  background-color: white !important;
  color: $color-text-gray !important;
  border-color: $color-text-gray !important;
  opacity: 1 !important;
}


.ShortModalCancelButton {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px  !important;

  border: 1px solid $btn-accept-hover;
  // margin-top: 10px !important;
}


.StandarModalCancelButtonLogOut:hover, .ShortModalCancelButton:hover  {
  background-color: #c1c1c1 !important;
}

.StandarModalRejectButton {
  background-color: $danger !important;
  color: $light-white !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  margin-top: 10px !important;
}

.StandarModalRejectButton:hover {
  background-color: $danger-hover !important;
}

/*BUTTONS STANDAR*/
.StandarModalConfirmButtonStandar {
  background-color: $btn-accept-color !important;
  color: $primary !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  margin-top: -20px !important;
  border: 0px !important;
}
.StandarModalConfirmButtonStandar:hover {
  background-color: $btn-accept-hover !important;
}
.StandarModalCancelButtonStandar {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  border: 1px solid $btn-accept-hover;
  margin-top: -20px !important;
}
.StandarModalCancelButtonStandar:hover {
  background-color: #c1c1c1 !important;
}
.StandarModalConfirmButton:hover {
  background-color: $btn-accept-hover !important;
}
.StandarModalCancellButton:hover {
  background-color: $danger-hover !important;
}
.StandarModalCancelButton {
  background-color: $danger !important;
  color: $btn-accept-txtcolor !important;
  font-size: 18px !important;
  padding: 5px 20px 5px 20px !important;
  margin-top: 0px !important;
}
.StandarModalCancelButton:hover {
  background-color: $danger-hover !important;
}

.StandarModalConfirmButtonLogOutRed {
  background-color: $danger !important;
  color: $btn-accept-txtcolor !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  margin-top: 10px !important;
  border: 0px !important;
}
.StandarModalConfirmButtonLogOutRed:hover {
  background-color: $danger-hover !important;
}

.StandarModalCancelButtonLogOut {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
}
.StandarModalCancelButtonLogOut:hover {
  background-color: #c1c1c1 !important;
}
/*BUTTONS STANDAR*/
.StandarModalConfirmButtonStandar {
  background-color: $btn-accept-color !important;
  color: $primary !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  margin-top: -20px !important;
  border: 0px !important;
}
.StandarModalConfirmButtonStandar:hover {
  background-color: $btn-accept-hover !important;
}
.StandarModalCancelButtonStandar {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  border: 1px solid $btn-accept-hover;
  margin-top: -20px !important;
}
.StandarModalCancelButtonStandar:hover {
  background-color: #c1c1c1 !important;
}
.StandarModalConfirmButton:hover {
  background-color: $btn-accept-hover !important;
}
.StandarModalCancellButton:hover {
  background-color: $danger-hover !important;
}
.StandarModalCancelButton {
  background-color: $danger !important;
  color: $btn-accept-txtcolor !important;
  font-size: 18px !important;
  padding: 5px 20px 5px 20px !important;
  margin-top: 0px !important;
}
.StandarModalCancelButton:hover {
  background-color: $danger-hover !important;
}

.StandarDangerButtonLarge {
  background-color: $danger !important;
  color: $btn-accept-txtcolor !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 220px !important;
  border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
}
.StandarDangerButtonLarge:hover {
  background-color: $danger-hover !important;
}

/*INPUTS*/
.StandarModalInput {
  padding: 2px 5px 2px 5px !important;
  border: 3px solid $btn-accept-color !important;
}
.StandarModalInput:focus {
  border: 3px solid $btn-accept-hover !important;
  -webkit-box-shadow: 0px 0px 9px 0px $btn-accept-border !important;
  box-shadow: 0px 0px 9px 0px $btn-accept-border !important;
}
/*FOOTERs*/
.StandarModalFooterLogOut {
  position: absolute !important;
  width: 100% !important;
  top: 100px !important;
}
.StandarModalFooterRejectOrder {
  position: absolute !important;
  width: 100% !important;
  top: 280px !important;
}
/*REJECT ORDERs MODAL*/
.rejectModalTitle {
  text-align: left;
  font-size: 1.25rem;
  margin-top: 10px;
  padding: 0px;
  margin-left: 0px;
  padding-left: 0px !important;
}
.swal2-radio {
  padding-left: 10px !important;
  margin: 0px 0px 15px 0px !important;

  label {
    font-weight: normal !important;
    font-size: 1.25rem;
  }
}
/*ERROR MODAL*/
.StandarModalHtmlContainerError {
  margin: 0px !important;
  padding-left: 0px !important;
}
.errorModal {
  display: flex;
  justify-content: center;
  padding-top: 3% !important;
  h5 {
    font-size: 16px;
    text-align: center;
    margin: 20px 10px 10px 20px;
  }
  .StandarModalConfirmButtonError {
    background-color: $btn-accept-color !important;
    color: $btn-accept-txtcolor !important;
    font-size: 14px !important;
    padding: 8px 20px 8px 20px !important;
    width: 100px !important;
    margin: 10px 10px 0px 10px !important;
    border: 0px !important;
    float: right !important;
  }
  .StandarModalConfirmButtonError:hover {
    background-color: $btn-accept-hover !important;
  }
}

.swal2-icon {
  margin-top: 16% !important;
  margin-bottom: -8% !important;
}

.message-container {
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.message-container-input {
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  margin-top: 12%;
  font-size: 16px;
}

.vehicle-input-label label  b {
color: darken(#9a9a9a ,15%) !important;
}