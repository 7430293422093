.modal-header {
    background-color: #F5F5F5;
    line-height: 20px !important;
    height: 57px;
    max-height: 57px;
    size: 13px;
    color: $text-color !important;
    font-size: 20px !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    margin-right: -0.1%;
}

.modal-title{
    font-weight: bold !important;
}

.modal-close-button {
    width: unset !important;
    height: unset !important;
}
