.container-desk-routes {
  display: flex;
  gap: 2%;
  flex-direction: row;
}

.routes-container {
  width: 30%;
}

.right-container-routes {
  width: 100%;
  @media (min-width: 768px) {
    width: 70%;
    
  }
}

.pagination-buttons-menu {
  display: flex;
  justify-content: center;
  gap: 2%;
  .button_arrow:disabled {
    cursor: unset;
    color: rgba(0, 0, 0, 0.18);
    fill: rgba(0, 0, 0, 0.18);
  }
  .button_arrow {
    border: none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
}

.pagination-counter {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #707070;
}

.details-routes-container {
  width: 100%;
}

.routes-shippings {
  width: 100%;
}

.item-border {
  border: 1px solid;
  color: black;
}

.modal-dispath-route {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4 {
    margin-top: 15%;
  }
}

.content-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .container-desk-routes {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1120px) {
  .container-desk-routes {
    flex-direction: column;
  }
  .routes-container {
    width: 100%;
  }
  .details-routes-container {
    width: 70%;
  }
  .routes-shippings {
    width: 70%;
  }
}

@media only screen and (max-width: 1298px) {
  .container-desk-routes {
    flex-direction: column;
  }
  .routes-container {
    width: 100%;
  }
  .details-routes-container {
    width: 100%;
  }
  .routes-shippings {
    width: 100%;
  }
}
