.cfIiYD div:first-child{
    overflow: visible !important;
}

.tooltip2 {
  position: relative !important;
  display: block;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: -2.25em;
  left: 0;
  z-index: 999 !important;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}