/* TO MODALS WITH 3 BUTTONS */
.ModalConfirmButtonTfi {
  background-color: $btn-accept-color !important;
  color: $btn-accept-txtcolor !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 146px !important;
  border: 1px solid $btn-accept-color;
  margin-top: 10px !important;
}

.ModalConfirmButtonTfi:hover {
  background-color: $btn-accept-hover !important;
}

.ModalCancelButtonTfi {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 146px !important;
  border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
}

.AlertButtonFinish {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 5px 20px 5px 20px !important;
  width: 100% !important;
  border: 1px solid $btn-accept-hover;
  margin-bottom: 0.5%;
}

.ModalCancelButtonTfi:hover,
.AlertButtonFinish:hover {
  background-color: #c1c1c1 !important;
}

.ModalActionButtons {
  margin-top: 4em;
}

.ModalActionButtons>* {
  margin-right: 1em;
  justify-content: space-between;
}

.style-as-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}