.hide-btn{
    pointer-events: none;
    background-color: #f3efef;
    opacity: .6;
}
.w-same{
    width: 120px;
    height: 40px;
    margin: 0 !important;
    margin-right: 15px !important;
    &:last-child{
        margin-right:  0 !important;

    }
}

h5.receipt-vehicle-download-title{
    color: black;
    font-weight: bold;
}
.vehicle-receipt > div > div > div > div > button.StandarModalConfirmButtonLogOut  {
    margin-top: 0 !important;

    @media (min-width: 768px) {
        display: block;
        margin:  0 auto; 
    }
}

div.vehicle-receipt > div > div  > h3.card-title{
    @media (max-width: 768px) {
        margin:  20px auto !important; 
        width: 100%;
        text-align: center;
    }
}
div.vehicle-receipt > div > div.card-header{
    @media (max-width: 768px) {
        text-align: center;
    }
}