@import './Settings.scss';
@import 'cropperjs/dist/cropper.css';
@import './ModalContent.scss';
@import './ModalCropper.scss';
@import './ModalsStandarStyle.scss';
@import './Login.scss';
@import './RoutesContainer.scss';
@import './TFI.scss';
@import './Telemarketing.scss';
@import './BusinessPartnerLevelBoxes.scss';
@import './VehicleRegisterProgressbar.scss';
@import './VehicleReceipt.scss';

html,
body {
  color: $text-color;
  font-family: $font;
}

.textCapitalize {
  text-transform: capitalize;
}

.main-sidebar {
  background-color: $sidebar-bg-color !important;
}

.main-header {
  background-color: $primary;

  i {
    color: $text-color !important;
  }
  color: $text-color !important;
}

.btn-normalText {
  color: $text-color !important;
}

//GLOBAL BUTTONS TO SET ACTIONS
.buttonFirstAction {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  width: 100% !important;
  border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
}
.buttonFirstAction:hover {
  background-color: #c1c1c1 !important;
}
.buttonSecondAction {
  background-color: $btn-accept-color !important;
  color: white !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  width: 100% !important;
  margin-top: 10px !important;
  border: 0px !important;
}
.buttonSecondAction:hover {
  background-color: $btn-accept-hover !important;
}
/*GLOBALS CARDS*/
.cardTitle {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.cardContent {
  font-size: 1.25rem;
}

.cardTitle2 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.cardContent2 {
  font-size: 1rem;
}
.cardDescription {
  font-size: 1rem;
}

.noPaddingParagraph p {
  margin-bottom: 0px;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.item-menu-container {
  background-color: $menu-active-color !important;
  // color: $text-color !important;
  color: white !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 4px;

  i {
    color: white;
  }
}

.main-footer {
  font-size: 14px;
  a {
    font-weight: bold;
    color: $text-color;
  }
}

.navbar-light .navbar-nav .nav-link {
  :not(.main-header, .header-normalText) {
    color: white;
  }
  // color: #fff !important;
}

.header-normalText {
  color: #282828;
}

.header-title {
  font-weight: bold !important;
}
//Login
.sidebar-mini .layout-fixed {
  overflow: hidden;
  background: $danger !important;
}

//OVERRIDE COLORS PRINCIPAL WIDGETS
.sidebar-dark-primary,
.header-gray {
  background-color: $gray-dark;
}

.content-wrapper {
  background-color: $light-white;
}

.bg-info {
  background-color: $primary;
  color: $gray-dark;
}

.btn-secondary,
secondary {
  background-color: $secondary;
}

.nav-sidebar .nav-item > .nav-link {
  // background-color: white;
  color: $text-color;
  font-size: 14px;
}

.nav-item .list-unstyled li a {
  background-color: white !important;
  border: 4px;
}

[class*='sidebar-dark-'] .sidebar a {
  color: $text-color;
  font-size: 16px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.link-activo {
  background-color: $light-white !important;
  color: black !important;
}

.direct-chat-primary .right > .direct-chat-text {
  background-color: $info;
  border-color: $info;
  color: $light-white;
}

.small-box-footer {
  color: $secondary !important;
}

.breadcrumb {
  text-transform: uppercase;
}

//Start choosen header
.choosenHeader {
  border: 1px solid $secondary;
  width: 130px;
  text-align: center;
  height: 35px;
}
//end choosen header

//ContentHeader input date COLORADO START
.inputDateColorado {
  border: 1px solid $secondary;
  width: 150px;
  height: 35px;
  padding: 0px 10px 0px 10px;
}

.breadcrumb-item > a {
  color: $text-color !important;
}

.card-header {
  h3 {
    font-size: 20px;
    color: $light-white !important;
  }
  background-color: $card-header;
  border: 0px !important;
  box-shadow: none !important;
  padding: 35px 0px 15px 0px;
}

.card-header > .card-title,
.header-title {
  color: $color-text-gray !important;
}

.card-header .card-subTitle {
  color: #cfcfcf;
  // margin-top: 4px;
}

.header-title {
  font-size: 30px !important;
  font-weight: bold;
}

.card-body {
  color: $secondary;
  border-radius: 4px !important;
}

.card-body #headerFilters {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
}

// .card {
//   box-shadow: 0px !important;
//   // margin-bottom: 1rem;
// }

.whiteHeader {
  background-color: white;

  h3 {
    color: black !important;
    font-weight: bold;
  }
  border-bottom: 1px solid $secondary;
}

.title-filter {
  color: $text-color;
  font-weight: normal !important;
  text-transform: none !important;
  cursor: pointer;
}

.layout-configs {
  // height: 100%;
  background-color: white;
  .container-data-table > div {
    max-height: max-content;
  }
  .rdt_TableHead {
    font-weight: bold !important;
    font-size: 16px;
    color: $layout-table-content-color;
    text-transform: uppercase;
    z-index: 1;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      font-size: 14px;
      color: $layout-table-content-color;

      border-bottom-style: none;
      border-bottom-width: 1px;
      border-bottom-color: none;
    }
    .cpxBoR {
      background-color: #f5f5f5;
    }

    .hMXdVY {
      background-color: white;
    }
  }
  .rdt_Pagination {
    font-size: 14px;
    color: $layout-table-content-color;
    justify-content: center;
  }
  .rdt_TableHeadRow {
    border-bottom-width: 2.67px;
    border-bottom-color: #f3f3f3;
  }
}

//BUTTONS
.btn {
  border-radius: 0%;
}

.btn-accept {
  color: white;
  font-size: 14px;
  background-color: $btn-accept-color !important;
  border-color: $btn-accept-border !important;
  box-shadow: none !important;
  border-radius: 4px 4px 4px 4px !important;
}

.btn-accept-white {
  background-color: white !important;
  color: $color-text-gray !important;
  border-color: $color-text-gray !important;
  opacity: 1 !important;
  border-radius: 4px !important;
}

.btn-accept:hover {
  color: $btn-accept-txtcolor !important;
  background-color: $btn-accept-hover !important;
  border-color: $btn-accept-border !important;
}

.btn-accept-white:hover {
  background-color: #c1c1c1 !important;
  color: $color-text-gray !important;
}

.btn-accept:focus,
.btn-accept.focus {
  color: $color-turquesa-hover !important;
  background-color: $btn-accept-hover !important;
  border-color: $btn-accept-border !important;
  box-shadow: 0 0 0 0 rgba(16, 89, 167, 0.5);
}

.btn-accept.disabled,
.btn-accept:disabled {
  color: $color-turquesa-hover !important;
  background-color: $btn-accept-color !important;
  border-color: $btn-accept-border !important;
}

.btn-accept:not(:disabled):not(.disabled):active,
.btn-accept:not(:disabled):not(.disabled).active,
.show > .btn-accept.dropdown-toggle {
  color: $color-turquesa-hover !important;
  background-color: $btn-accept-color !important;
  border-color: $btn-accept-border !important;
}

.btn-accept:not(:disabled):not(.disabled):active:focus,
.btn-accept:not(:disabled):not(.disabled).active:focus,
.show > .btn-accept.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(16, 89, 167, 0.5);
}

.btn-info,
info {
  color: $btn-info-txtcolor !important;
  background-color: $info !important;
}

.btn-info:hover {
  color: $btn-info-txtcolor !important;
  background-color: $info-hover !important;
}

.btn-info:focus,
.btn-info.focus {
  color: $btn-info-txtcolor;
  background-color: $info-hover !important;
  border-color: $info-border !important;
  box-shadow: 0 0 0 0 rgb(63, 31, 206) !important;
}

.btn-danger,
danger {
  background-color: $danger !important;
  border-radius: 4px !important;
}

.btn-danger:hover {
  color: $btn-danger-txtcolor;
  background-color: $danger-hover !important;
}

.btn-danger:focus,
.btn-danger.focus {
  color: $btn-danger-txtcolor;
  background-color: $danger-hover !important;
  border-color: $danger-border !important;
  box-shadow: 0 0 0 0 rgb(87, 8, 8) !important;
}

[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link:hover,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link:focus {
  background-color: $menu-hover-colorBG !important;
  color: $menu-hover-txtcolor;
}

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link, .collapse-items-menu:hover, .item-menu:hover {
//   background-color: $menu-hover-colorBG !important;
//   color: $menu-hover-txtcolor;
// }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.collapse-items-menu:hover,
.item-menu:hover {
  // background-color: $menu-hover-colorBG !important;
  color: $menu-hover-txtcolor;
}

.link-hover-color:hover {
  background-color: $menu-hover-colorBG !important;
  color: $menu-hover-txtcolor;
}
.link-hover-color.link-activo:hover,
.link-activo:hover {
  background-color: $primary !important;
  color: $light-white;
}

[class*='sidebar-dark-'] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item:hover > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item > .nav-link:focus {
  background-color: $menu-hover-colorBG;
  color: $menu-hover-txtcolor;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 4px;
}

[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active:hover,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active:focus {
  background-color: $submenu-active-color !important;
  color: #282828 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 4px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $checkbox-colorBG !important;
}

.toggle-up {
  transform: rotate(90deg);
}

.toggle-down {
  transform: rotate(0);
}

.buttons-recovery {
  display: flex;
  justify-content: flex-end;
}

.control-btn-box {
  display: flex;
  flex-direction: row-reverse;
  gap: 2%;

  // @media (max-width: 600px) {
  //   display: none;
  // }
}

.control-btn-box-right {
  display: flex;
  gap: 2%;
}

.error {
  color: $danger;
}

.no-border {
  border: 0px !important;
}

.img-sw {
  width: 150px !important;
}

.fit-img {
  object-fit: cover;
}

//Permissions checkbox
.permissions-cardH {
  background-color: $sidebar-bg-color !important;
  h3 {
    color: $text-color !important;
  }
}

.card-wrapper {
  label {
    color: $text-color;
    font-size: 14px;
  }
  display: flex;
  flex-direction: row;
  @media (max-width: 670px) {
    display: block;
    flex-direction: column;
  }
}

//Loading Layer
.container-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background-color: $login-bg-color;
  padding: 0;
  margin: 0;
}
.section-loading {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $light-white;
    margin-bottom: -25%;
    margin-top: 10%;
    font-size: 3em;
  }
}

//Contents Fade
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content-fade-login {
  animation: fadeIn linear 0.7s;
  -webkit-animation: fadeIn linear 0.7s;
  -moz-animation: fadeIn linear 0.7s;
  -o-animation: fadeIn linear 0.7s;
  -ms-animation: fadeIn linear 0.7s;
}

.content-fade {
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
}

// 404 ERROR PAGE
.container-notfound {
  display: flex;
  flex-direction: column;
  background-color: $light-white;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.title-notfound {
  font-size: 2em;
  font-weight: 500;
}

.subtitle-notfound {
  font-size: 1em;
  font-weight: 400;
}

//LOGOS SIDEBAR
.brand-image.logo-xl {
  display: inline;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5px;
}

.brand-image.logo-xs {
  display: inline;
  margin-left: 0;
  width: 50px;
  height: 100px;
  margin-right: auto;
}

//SEARCH BAR LAYOUT TABLE
.position-searchInput {
  margin-left: 0px !important;
  margin-bottom: 2%;
}

#searchBar {
  background-image: url('../Assets/icons/magnifying-glass.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 98%;
}

#searchBar::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-color;
  // font-weight: bold;
  opacity: 1; /* Firefox */
}

.container-pic {
  max-width: 205px !important;

  .avatar-preview {
    width: 40px !important;
    height: 40px !important;
    border: 2px solid $light-white;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.1);
    object-fit: cover !important;

    > img {
      width: 100% !important;
      height: 100% !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }
}

.position-btnExport {
  margin: auto !important;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 12px;
  margin-left: -3%;
  min-width: 40px;
}
.icon2 {
  width: 25px;
  height: 25px;
}

.icon3 {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
}

.boxActive {
  background-color: $btn-accept-color;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
}

.input-field {
  margin-left: -2.9% !important;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  padding-left: 4% !important;
}

.platform-selector {
  select {
    color: $layout-table-content-color !important;
  }
}
.btn-more-actions {
  left: -90% !important;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none;
}

.detail-hover:hover {
  b {
    color: $primary;
  }
  p {
    background-color: $card-header;
  }
}

a {
  color: $primary;
}

.swal2-actions {
  gap: 20px;
}

@media (max-width: 500px) {
  #boxLogin {
    width: 90% !important;
  }
}

.header-text-details {
  font-size: 1rem;
  color: #707070;
  font-weight: bold;
  text-transform: uppercase;
}

.content-text-details {
  color: #707070;
  font-size: 1.25rem;
}

.box-separator {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.box-separator-top {
  border-top: 1px solid #ebebeb;
  margin-top: 20px;
}

.cost-header {
  font-size: 20px;
  color: #707070;
}

.cost-text {
  font-size: 20px;
  color: #707070;
}

.process-btn {
  font-size: 16px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
}

.process-btn__red {
  background-color: #c3353f;
  color: #ffffff;

  &:hover {
    background-color: #921823;
    color: #ffffff;
  }
}

.process-btn__gray {
  background-color: #707070;
  color: #ffffff;

  &:hover {
    background-color: #4f4f4f;
    color: #ffffff;
  }
}

.product-detail-text {
  font-size: 14px;
  color: #707070;
}

.badge {
  color: $badge-text-color !important;
  border-radius: 100%;
  width: auto;
  min-width: 12%;
  min-height: 12%;
  padding: 4%;
}

.badge-color {
  margin-top: -3%;
  background-color: $badge-color !important;
}

.badge-conf {
  border-radius: 60%;
  padding-bottom: 13%;
  padding-top: 13%;
}

.nav-link.active {
  span {
    color: $light-white;
  }
}

.actions-btn {
  gap: 12px !important;
}

.btn-actions-table {
  gap: 5%;
  a {
    min-width: max-content !important;
  }
  span {
    min-width: max-content !important;
    width: 100px;
  }
}
.noGap {
  gap: 0%;
  a {
    margin-left: 10px !important;
  }
}

.uploadFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.uploadFile + label {
  width: 70%;
  text-align: center;
  i {
    align-items: 10px;
  }
}

.swal2-radio {
  display: block !important;

  label {
    width: 100%;
  }
}

/*TIMELINE*/
.timeLine-body {
  height: 90px;
  max-height: 90px;
  overflow-y: auto;
  padding: 0px 15px 0px 2px !important;
  // margin-top: -13px;

  ul {
    list-style: none;
  }
  li:not(:first-child) {
    margin: 30px 0px 30px 0px;
  }
  li:last-child {
    margin: 30px 0px 0px 0px;
  }
  li:first-child {
    margin-top: 20px;
  }
  li:only-child {
    margin-top: 15px;
  }

  i {
    margin-left: -1.5em;
    margin-right: 0.5em;
    size: 3x;
    color: $danger;
  }

  #time {
    display: block;
    float: right;
  }
}
.timeLine-body::-webkit-scrollbar {
  width: 10px;
  background-color: #c1c1c1;
  border-radius: 2px;
}
.timeLine-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #8a8a8a;
}

.listNoWhite .menu-active-hangfire {
  color: $color-text-titles !important;
}

/* INTERNAL NOTES */
.processing-file {
  pointer-events: none;
  opacity: 0.4;
}
.internalNotes-body {
  ul {
    padding: 0px 5px 0px 5px;
  }
  ul li {
    list-style: none;
  }
  li p {
    background-color: $light-white;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    font-size: 1.25rem;
  }

  .boxHistory {
    height: 400px;
    overflow-y: auto;

    .user {
      font-size: 1rem;
      font-weight: bold;
    }

    a:hover {
      color: $info;
    }
  }
  .boxHistory::-webkit-scrollbar {
    width: 10px;
    background-color: #c1c1c1;
    border-radius: 2px;
  }
  .boxHistory::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8a8a8a;
  }

  .boxInput {
    width: 100%;
    margin-top: 20px;
    height: 40px;

    #internalNotesDocument {
      display: none;
    }

    input[type='text'] {
      width: 70%;
      height: 40px;

      background-color: $light-white;
      border: 1px;
      border-radius: 4px 0px 0px 4px;
      padding-left: 10px;
      padding-right: 10px;
    }
    input[type='text']:focus {
      outline: none !important;
      border: 1px solid $secondary;
    }
    #labelDocument {
      width: 10%;
      height: 40px;
      background-color: $light-white;
      // display: contents;
      line-height: 40px;
      background-color: #b6b6b6;
      text-align: center;
      cursor: pointer;
      color: $secondary;
    }
    #labelDocument:hover {
      background-color: #a0a0a0;
    }
    button {
      width: 20%;
      height: 40px;
      background-color: $btn-accept-color;
      border: 0px;
      border-radius: 0px 4px 4px 0px;
      color: white;
    }
    button:hover {
      background-color: $btn-accept-hover;
    }
  }
  .img-internal-notes {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: #4f4f4f outset;
    transition: 0.5s ease;
    backface-visibility: hidden;
    opacity: 1;
  }
  .hover-overlay:hover {
    opacity: 0.6;
  }
}

.preview-docs {
  background-color: aliceblue;
  padding: 3%;
  display: flex;
  gap: 8%;
  align-items: center;
}

/*FULL CALENDAR*/
.FullCalendar {
  position: relative;
  /*Full calendar's title*/
  .fc-toolbar-title {
    text-transform: capitalize;
  }

  .bg-calendar-day {
    background-color: #20c4cb;
    a {
      color: white !important;
    }
  }
  /*Each event box*/
  .eventBox {
    background-color: $light-yellow;
    border-radius: 4px;
    padding: 10px;
    width: 90%;
    margin: 0px auto;
    height: auto;
    line-height: 40px;
    cursor: pointer;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: auto;

    h5 {
      font-size: 14px;
      color: $primary;
      text-transform: capitalize;
    }
    h6 {
      // font-size: 12px;
      color: $color-text;
      text-transform: capitalize;
    }
  }
  a {
    color: $color-text;
  }
  .eventBox:hover {
    background-color: $light-yellow-hover;
  }
  .eventBox::-webkit-scrollbar {
    height: 8px;
    background-color: $secondary;
    border-radius: 2px;
  }
  .eventBox::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $btn-accept-border;
  }
}

.calendarReceiveContent {
  // width: 60px;

  h6 {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

/*FULL CALENDARs MODAL*/
#quotesModalBox {
  padding: 20px 30px 0px 30px;
  text-align: left;
  max-height: 300px;
  overflow-y: scroll;
  overflow: auto;
  margin: 0px 12px 0px 0px !important;

  .quoteAttribute {
    color: #000;
    font-weight: bold;
    text-align: left !important;
  }
  .quoteAttributeRes {
    float: right;
    text-align: right;
    text-transform: capitalize;
    // width: 250px;
    height: auto;
    max-height: 25px;
    overflow: auto;
  }
  .quoteAttributeRes::-webkit-scrollbar {
    width: 0px;
    background-color: $primary-blue;
    border-radius: 2px;
  }
  .quoteAttributeRes::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $btn-accept-border;
  }
  h4 {
    margin-bottom: 15px;
    color: $btn-accept-border;
    background-color: $light-yellow;
    width: 95%;
    border-radius: 0px 10px 10px 0px;
    padding: 5px 5px 5px 10px;
    font-size: 18px;

    animation-name: dateAnimation;
    animation-duration: 0.7s;
    -moz-animation-timing-function: inherit;
    -webkit-box-shadow: 5px 5px 16px -5px rgba(0, 0, 0, 0.84);
    box-shadow: 5px 5px 16px -5px rgba(0, 0, 0, 0.84);

    i {
      margin-right: 5px;
    }
  }
  h5 {
    font-size: 18px;
  }
  .docksBox {
    float: right;
  }

  .docksItem {
    background-color: $text-color;
    border-radius: 7px;
    color: $light-white;
    padding: 3px 6px 6px 6px;
    cursor: context-menu;
  }
  .docksItem:not(:last-child) {
    margin: 0px 6px 0px 6px;
  }
  .docksItem:last-child {
    margin: 0px 0px 0px 6px;
  }
  .docksItem:hover {
    background-color: #151515;
  }
}
#quotesModalBox::-webkit-scrollbar {
  width: 10px;
  background-color: $primary-blue;
  border-radius: 2px;
}
#quotesModalBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

/*PROVIDERS TABLE*/
.providersTableBox {
  position: relative;
  height: 320px;
  max-height: 320px;
  overflow: auto;
  // display: block;

  .header {
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
    }
  }

  #providersTable {
    width: 100%;
    thead {
      background-color: #1c1c1c;
      color: white !important;
    }
    tbody {
      // tr:not(.extraCamp) {
      //   cursor: pointer;
      // }
      .rowError {
        background-color: $danger;
        color: $light-white;
      }
      .rowCorrect:hover:not(.extraCamp) {
        background-color: $light-yellow-hover;
      }
      .rowError:hover:not(.extraCamp) {
        background-color: $danger-hover;
      }

      input[type='checkbox'] {
        width: 100% !important;
      }
      input[type='number'] {
        width: 80%;
      }
      i {
        text-align: center;
        width: 100%;
      }
    }
  }
}
.providersTableBox:not(:first-child) {
  margin-top: 30px;
}
.providersTableBox::-webkit-scrollbar {
  height: 10px;
  background-color: $primary-blue;
  border-radius: 2px;
}
.providersTableBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

/*ORDERS TABLE*/
.ordersTableBox {
  position: relative;
  height: auto;
  max-height: 320px;
  overflow: auto;
  display: block;

  .header {
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
    }
  }

  #ordersTable {
    width: 100%;
    th,
    td {
      border: 1px solid white;
    }

    thead {
      background-color: white;
      color: $secondary !important;

      tr {
        border-bottom: 2px solid #e2e2e2 !important;
      }
    }

    tr td {
      border-bottom: 1px solid #e2e2e2 !important;
      background-color: white !important;
    }

    tbody {
      .rowError {
        background-color: $danger;
        color: $light-white;
      }
      .rowCorrect:hover:not(.extraCamp) {
        background-color: $light-yellow-hover;
      }
      .rowError:hover:not(.extraCamp) {
        background-color: $danger-hover;
      }

      input[type='checkbox'] {
        width: 100% !important;
      }
      input[type='number'] {
        width: 80%;
      }
      i {
        text-align: center;
        width: 100%;
      }
    }
  }
}
.ordersTableBox:not(:first-child) {
  margin-top: 30px;
}
.ordersTableBox::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: $primary-blue;
  border-radius: 2px;
}
.ordersTableBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

.providerScheduleModalTable {
  padding: 20px !important;

  .boxItem {
    text-align: left !important;
  }

  .providersTableBox {
    height: 220px !important;
    max-height: 220px !important;
  }
}

.boxQuotesProvider {
  h4 {
    margin-top: 2%;
    font-size: 18px;
    color: #000;
    font-weight: 900;
  }
  h5 {
    font-size: 24px;
    color: $color-text;
  }
  .resultData {
    color: $secondary;
  }
}

.scanCodeCam {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 30vh;
  overflow: hidden;
}
// .scanCodeCam::before {
//   content: url('../Assets/Images/scanIcon.png');
//   position: absolute;
//   top: 20%;
//   left: 42%;
//   transform: scale(0.4);
// }
.gallery {
  display: flex;
  overflow-x: scroll;

  .gallery-item i {
    cursor: pointer;
    color: black;
  }
}
.gallery-item img {
  width: 100%;
  height: 100%;
}

/*ORDERS DETAILS*/
.ordersTableDetails {
  max-height: 200px;
  width: 770px;
  min-width: 400px;
  max-width: 770px;
  overflow-y: auto;
  overflow-x: scroll;
}

@keyframes dateAnimation {
  0% {
    margin-left: -300px;
    visibility: hidden;
  }
  100% {
    margin-left: 0px;
    visibility: visible;
  }
}

.one-centered {
  display: flex;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.text-one {
  padding: 10%;
  font-size: 20px;
}

//HIDE LICENSE MESSAGE FROM FULL CALENDAR
.fc-license-message {
  display: none;
}

.docksItem {
  background-color: $text-color;
  border-radius: 7px;
  color: $light-white;
  padding: 3px 6px 6px 6px;
  margin-right: 2%;
  cursor: context-menu;
}

.actions-buttons-scan {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 2% !important;
}

.card-stretch {
  display: grid;
}

.actions_orders_buttons {
  display: flex;
  justify-content: space-between;
  gap: 5%;
  padding-bottom: 3%;
}

.action_orders_one_button {
  display: flex;
  justify-content: flex-end;
  gap: 5%;
  padding-bottom: 3%;
}

//
.inptu-manual-content {
  display: flex;
  align-content: center;
  justify-content: center;
}
// Scanner
.scannerComp canvas {
  display: none;
}
#scannerComp canvas,
video {
  width: 400px;
  height: 300px;
  // position: absolute;
  top: 0;
  left: 0;
}

.hidePagination {
  .rdt_Pagination {
    display: none;
  }
}

//mainStats
.mainStatsBox {
  h3 {
    color: #707070;
    font-weight: bold;
    font-size: 40px;
    // text-align: center;
  }
  h5 {
    color: #707070;
    font-size: 15px;
    // text-align: center;
  }
}
//end mainStats

//salesComparisonBox
.salesComparisonBox {
  h5 {
    font-weight: bold;
  }
}
//end salesComparisonBox

//SalesComparisonCheckItemsBox
.SalesComparisonCheckItemsBox {
  h5 {
    color: #707070;
    font-size: 16px;
    font-weight: none !important;
    text-transform: none;
  }
  h6 {
    color: #707070;
    font-weight: none !important;
    font-size: 14px;
  }
  .titleContainer {
    display: flex;
    align-items: baseline;

    input[type='checkbox'] {
      margin-right: 5px;
    }

    .boxColor {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .bodyContainer {
    margin-left: 40px;
  }
}
//end SalesComparisonCheckItemsBox

//start buttonsFixSpaces
.buttonsFixSpaces {
  .btn-actions-table {
    grid-gap: 0;
    gap: 0;
  }

  .btn:first-child {
    margin-right: 5px;
  }
}
//end buttonsFixSpaces

//Start displacementItemMain
.displacementItemMain {
  padding: 10px;
  h6 {
    font-weight: bold;
    text-transform: uppercase;
  }
}
//End displacementItemMain

//Start displacementSalesComparativeMain
.displacementSalesComparativeMain {
  .graphicsColors {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
//End displacementSalesComparativeMain

//Start DownloadReportsMain
.DownloadReportsMain {
  // width: 447px;
  padding: 10px;

  .swal2-actions {
    display: flex;
    flex-direction: row-reverse;
  }

  h5 {
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .datesBox {
    .col-12 {
      display: flex;
      justify-content: flex-start;
    }
    input,
    label {
      font-size: 15px;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    input {
      border: 0.5px solid grey;
    }
    label {
      display: flex;
    }
  }

  .dataCheckBox {
    display: flex;
    justify-content: left;
    input {
      margin-right: 5px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
    .col-6 {
      text-align: start;
    }
  }

  .buttonsBox {
    display: flex;
    justify-content: space-between;
    span {
      width: 100px;
    }
    .cancel {
      background-color: $light-white !important;
      color: $btn-accept-hover !important;
    }
    .cancel:hover {
      background-color: #c1c1c1 !important;
    }
  }
}

// hangFire menu
.menu-active-hangfire {
  background-color: $primary !important;
  color: $light-white !important;
}
.menu-hangfire {
  background-color: $sidebar-bg-color;
  color: $gray-dark;
}
.menu-hangfire-container {
  background-color: $sidebar-bg-color !important;
}

.menu-hangfire:hover {
  background-color: $menu-hover-colorBG;
  color: $menu-hover-txtcolor;
}

//End DownloadReportsMain

//Start ubicationsBox
.ubicationsBoxWarehouseDashboard {
  text-align: center;
  div:first-child {
    border-right: 1px solid $secondary;
  }
  div:last-child {
    border-left: 1px solid $secondary;
  }
}
//End ubicationsBox

// Layout card Table

.col-layout-card-table > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 3fr));
  grid-gap: 1rem;
}
.actions-buttons-layout-table {
  grid-gap: 4%;
  gap: 4%;
  display: flex;
  justify-content: flex-end;
  min-width: 290px;
}

.cExfjL {
  justify-content: flex-end !important;
}

//Start percentageBarMain
.percentageBarMain {
  .barBox {
    display: flex;
    justify-content: space-between;

    .bar,
    .smallBar {
      width: 80%;
      display: flex;
      .completed {
        background-color: #ffd186;
      }
      .noCompleted {
        background-color: #ffefd5;
      }
    }

    .counter,
    .smallCounter {
      width: 15%;
      background-color: $danger;
      padding: 0px 10px 0px 10px;
      color: $light-white;
      text-align: center;
      border-radius: 3px;
      font-size: 16px;
    }

    .smallBar {
      width: 70%;
    }
    .smallCounter {
      width: 28%;
    }
  }

  .displayInBlockBarBox {
    display: block;
    .bar,
    .smallBar {
      width: 100%;
    }
    .counter,
    .smallCounter {
      width: 80px;
      margin-top: 10px;
    }
  }
}
//End percentageBarMain

.alignCards {
  .card {
    height: 100%;
  }
}

//Start highchartsButtonSymbol
.hideHighchartsButtonSymbol {
  .highcharts-button-symbol {
    display: none;
  }
}
//End highchartsButtonSymbol

//Start boxHighChartDescription
.boxHighChartDescription {
  display: flex;
  align-items: baseline;
  .square {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}
//End boxHighChartDescription

//Start hideHighchartsCaption
.hideHighchartsCaption {
  .highcharts-caption {
    display: none;
  }
}
//End hideHighchartsCaption

//Start labelWarehouseChoosen
.labelWarehouseChoosen {
  margin-left: 25px;
}
//End labelWarehouseChoosen

@media (max-width: 599px) {
  .labelWarehouseChoosen {
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .btn-card-layout-table {
    flex-direction: column;
    grid-gap: 5px;
    justify-content: flex-start !important;
    min-width: auto;
  }
}

@media (max-width: 992px) {
  //Start ubicationsBox
  .ubicationsBoxWarehouseDashboard {
    text-align: center;
    div:first-child {
      border-right: 0px;
    }
    div:last-child {
      border-left: 0px;
    }
  }
  //End ubicationsBox
}

@media (max-width: 1160px) {
  //Start percentageBarMain
  .percentageBarMain {
    .barBox {
      display: block;
      .bar,
      .smallBar {
        width: 100%;
      }
      .counter,
      .smallCounter {
        width: 100%;
      }
    }
  }
  //End percentageBarMain
}

@media (max-width: 1199px) {
  .alignCardsFix {
    margin-bottom: 17px;
  }
}

.row-perpage-select-card-layout {
  position: relative;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
  select {
    cursor: pointer;
    height: 24px;
    max-width: 100%;
    user-select: none;
    padding-left: 8px;
    padding-right: 24px;
    box-sizing: content-box;
    font-size: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    appearance: none;
    direction: ltr;
    flex-shrink: 0;
    word-wrap: normal;
  }
  svg {
    top: 0px;
    right: 0px;
    color: inherit;
    position: absolute;
    fill: currentcolor;
    width: 24px;
    height: 24px;
    display: inline-block;
    user-select: none;
    pointer-events: none;
  }
}

.display-none {
  display: none;
}

.btn-border-1 {
  border: solid 1px #413e3e !important;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.btn-trash {
  flex: none !important;
  padding: 0.7em;
}

.btn-thin {
  padding: 0.5em !important;
  padding-top: 0.2em !important;
  padding-bottom: 0.2em !important;
}

.conteo-no-filters .card-body header {
  display: none !important;
}

.status-assignment {
  padding: 0.5em !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

.bg-terminada,
.bg-iniciada {
  background-color: #6dbe85 !important;
  color: #fff !important;
}

.bg-pendiente {
  background-color: #d9d9d9 !important;
}

.bg-cancelada {
  background-color: #b20b1c !important;
  color: #fff !important;
}

.percentage {
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
}

.text-white {
  color: #fff !important;
}

.item-click {
  cursor: pointer;
}

.date-picker-size {
  padding: 3px 15px;
}

.boxLeyendColor {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.icon-color-sucess {
  color: #28a745;
}
.page-breaker {
  page-break-after: always;
}

.first-ticket {
  @media print {
    @page {
      size: 302px 1000px;
      background-color: #3454d1;
    }
  }
}

.print-first-ticket {
  // width: 1000px;
  // height: 10000px;
  margin: 0;
  padding: 0;
}

.btn-actions-bulk-counter-charges {
  border: 1px solid #000;
  margin-left: 5px;
  font-size: 15px;
}

.btn-actions-bulk-counter-charges:hover {
  background-color: #000;
  color: #fff;
}

.display-info-payments-finances {
  margin-top: 20px;
  height: auto;
  width: 100%;
  border: 2px solid black;
}

.title-display-info-payments-finances {
  width: 180px;
  font-size: 14px;
  margin-top: -10px;
  background: white;
  text-align: center;
}

.routes-menu-badge {
  padding: 10px;
  color: white !important;
  background-color: $secondary !important;
}

.collapse-items-menu {
  color: #000;
  cursor: pointer;
}
.shipment-routes-menu {
  width: auto;
  @media (min-width: 768px) {
    min-width: 300px !important;
  }
  background-color: $color-turquesa !important;
}

.remove-margin {
  margin: 0 !important;
}

.embed-container {
  width: 100%;
  height: 100vh;
}
.icon-actions-layout-table-minwidth {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: flex-start;
  align-content: flex-start;
}

.icon-actions-layout-table {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding: 12px;
  margin: 0px;
  font-size: 25px;
  background: none;
  border: none;
  color: #000;
}

.icon-actions-layout-table:hover {
  color: #fff;
  cursor: pointer;
  border-color: #000;
  background-color: #000;
}

.icon-actions-layout-table:disabled {
  cursor: not-allowed;
  color: #b8b8b8;
}

.icon-actions-layout-table:disabled:hover {
  color: #b8b8b8;
  cursor: not-allowed;
  background: none;
}

.icon-delete-document {
  color: rgb(255, 0, 0);
  font-size: 20px;
  cursor: pointer;
}

.icon-delete-document:hover {
  color: rgba(123, 29, 29, 0.84);
}

//Start status box
.statusBox {
  border-radius: 6px;
  color: $light-white;
  text-align: center;
  // max-width: 160px;
  min-width: 100px;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    align-items: center;
  }
}
.statusBox-open {
  background-color: #e1e1e1;
  color: $color-text;
}
.statusBox-approved,
.statusBox-partially-approved {
  background-color: #3454d1;
  color: $light-white;
}
.statusBox-processing,
.statusBox-draft,
.statusBox-processing {
  background-color: #2ab94c;
  color: $light-white;
}
.statusBox-closed,
.statusBox-cancelled,
.statusBox-negative-inventory,
.statusBox-error {
  background-color: #cc2d50;
  color: $light-white;
}

//End status box

.generate-report-container {
  padding: 0px;
  margin: 0px;
}

#selectOptions {
  background-color: $btn-accept-color;
  color: white;
  font-size: 14px;
  padding: 7px 20px 7px 20px;
  margin-top: 0px !important;
  width: 100%;
  margin-top: 10px;
  border-radius: 6px;
  border: 0px;
}

.documentLink {
  color: $btn-accept-color;
  // font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.manual-insertion {
  @media (max-width: 600px) {
    display: none;
  }
}
.hide-actions-buttons-scan {
  display: none !important;
  @media (min-width: 600px) {
    display: flex !important;
  }
}

.responsive-bottom-actions-buttons-scan {
  display: none !important;
  @media (max-width: 600px) {
    display: flex !important;
  }
}
.img-datatable-details {
  width: 24px;
  height: 24px;
}
.responsive-layout-table-details {
  .actions-buttons-layout-table {
    @media (max-width: 600px) {
      min-width: auto;
    }
  }
  .eZjKEM {
    @media (max-width: 600px) {
      min-width: 100px;
    }
  }
}
#business_partner_container_returns {
  margin-top: 20px;
  margin-left: -5px;
  padding: 0;
}
.responsive-products-reception-title > div > h3 {
  margin: 25px 5px !important;
}
.responsive-products-reception-grid-container {
  display: grid !important;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  gap: 15px;

  @media (min-width: 600px) {
    display: block !important;
    margin: 55px;
    grid-template-columns: unset;
  }
  justify-content: center;
}

//PAGINATION MODAL QUANTITYLABELS

.pagination-modal-quantity-labels {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-pagination-modal-quantity-labels {
  cursor: pointer;
  font-size: 20px;
  transition: transfrom 1s ease-in-out;
  background: none;
  border: none;
  margin: 0 10px;
  width: 30px;
  height: 30px;
}

.arrow-pagination-modal-quantity-labels:hover {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transform: scale(1.2);
  transition: background 0.6s ease-in-out;
}

.arrow-pagination-modal-quantity-labels:disabled {
  // cursor: not-allowed;
  color: #b8b8b8;
}

.arrow-pagination-modal-quantity-labels:disabled:hover {
  color: #b8b8b8;
  transform: scale(1);
  cursor: auto;
  background: none;
}

.current-page-modal-quantity-labels {
  margin: 0 10px;
}

//END PAGINATION MODAL QUANTITYLABELS

// TFI/CYCLECOUNTS ASSIGMENTS SECTION BUTTONS
.actions-buttons-general {
  padding-bottom: 2%;
  display: flex;
  justify-content: flex-end;
  gap: 2%;
}

.caption-text {
  font-size: 12px;
  width: max-content;
}

.tab-complete-verification {
  color: #559615;
  font-weight: bold;
}

.tab-uncomplete-verification {
  color: #af2525;
  font-weight: bold;
}
