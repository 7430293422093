.full-box {
  background-color: $login-box-inputs-color;
  padding: 2%;
  width: 500px;

  button {
    background-color: $danger;
    color: $light-white;
  }
  a {
    color: $danger;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: $login-text-color !important;
  }
}

.full-container {
  display: flex;
  flex-direction: column;
  background-color: $login-bg-color;
  align-items: center;
  height: 100vh;
  justify-content: center;
  span {
    font-size: 25px;
  }
}

.img-logo-full {
  width: 400px !important;
  // filter: invert(1) !important;
}

.actions-login {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  a {
    color: $login-text-color;
    text-decoration: underline;
  }
  label {
    color: $login-text-color;
    font-weight: lighter !important;
  }
}

.login-btn {
  padding-top: 8%;
  button {
    display: block;
    margin: auto;
    width: 80%;
    font-weight: bold;
    font-size: 16px;
  }
}

.input-icons-login {
  margin-bottom: 3%;
  width: 100%;
  input {
    background-repeat: no-repeat;
    background-position: 10px;
    display: flex;
    padding-left: 10%;
  }
  // #user {
  //   background-image: url('./../Assets/icons/user.svg');
  //   background-size: 16px 16px;
  // }
  // #pass {
  //   background-image: url('./../Assets/icons/lock.svg');
  //   background-size: 16px 16px;
  // }
}
.input-icons-login #user:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-image: url('./../Assets/icons/user.svg') !important;
  background-size: 16px 16px !important;
}

.input-icons-login #pass:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.iconInside {
  display: flex;
  .fa-eye {
    background-color: #fff;
    line-height: 36px;
    border: 1px solid #ced4da;
    border-radius: 0px 6px 6px 0px;
    padding-right: 12px;
    border-left: 0px;
    cursor: pointer;
  }
  .fa-user,
  .fa-lock {
    background-color: #fff;
    line-height: 35px;
    border: 1px solid #ced4da;
    border-radius: 6px 0px 0px 6px;
    padding-left: 12px;
    border-right: 0px;
    // cursor: pointer;
    color: #d2d2d2;
  }

  #pass,
  #pass1,
  #pass2 {
    border: 1px solid #ced4da;
    border-radius: 0px;
    border-right: 0px;
    border-left: 0px;
    padding-left: 4%;
  }
  #user {
    border: 1px solid #ced4da;
    border-radius: 0px 6px 6px 0px;
    border-left: 0px;
    padding-left: 4%;
  }
}

@media (max-width: 420px) {
  .img-logo-full {
    width: 270px !important;
  }
  .input-icons-login {
    input {
      padding-left: 14%;
    }
  }
  .actions-login {
    display: grid;
    justify-content: center;
  }
}
